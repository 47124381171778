:root {
	--toastify-toast-width: 100vw;
	--toastify-toast-min-height: 44px;
	--toastify-color-warning: #FFA000;
	--toastify-color-warning-dark: #FA9600;
	--toastify-color-warning-darker: #EC8E00;
	--toastify-color-error: #DB2242;
	--toastify-color-error-dark: #B80020;
	--toastify-color-error-darker: #A4001C;
	--toastify-color-success: #2E7D32;
	--toastify-color-success-dark: #09700E;
	--toastify-color-success-darker: #0A620E;
	--toastify-color-info: #043C77;
	--toastify-color-info-dark: #023366;
	--toastify-color-info-darker: #032548;
}

.Toastify__toast-container {
	padding: 0;
	top: 0;
}

.Toastify__toast {
	border-radius: 0;
	box-shadow: none;
	margin-bottom: 0;
	padding: 0;
}

.Toastify__toast-body {
	padding: 0;
	margin: 0;
}

.Toastify__toast-body > div:last-child {
	display: flex;
	height: 100%;
}

.Toastify__toast-iconWrapper {
	width: 44px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Toastify__toast-iconWrapper svg {
	fill: var(--toastify-color-light);
}

.Toastify__toast--error .Toastify__toast-iconWrapper {
	background-color: var(--toastify-color-error);
}

.Toastify__toast--success  .Toastify__toast-iconWrapper{
	background-color: var(--toastify-color-success-dark);
}
.Toastify__toast--info  .Toastify__toast-iconWrapper{
	background-color: var(--toastify-color-info-dark);
}
.Toastify__toast--warning  .Toastify__toast-iconWrapper{
	background-color: var(--toastify-color-warning-dark);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
	background-color: var(--toastify-color-error-darker);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
	background-color: var(--toastify-color-success-darker);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
	background-color: var(--toastify-color-info-darker);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
	background-color: var(--toastify-color-warning-darker);
}